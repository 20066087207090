import { FC, ReactNode } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import { SiderProps } from "antd/lib/layout";
import type { Location } from "history";
import { Button, Layout, Menu, Typography } from "antd";
import {
    ApartmentOutlined,
    AppstoreOutlined,
    HomeOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    ProfileOutlined,
    SkinOutlined,
    StarOutlined,
    UserOutlined,
} from "@ant-design/icons";
import "../assets/styles/MainMenu.less";

import constants from "../config/constants";
import { getRoute, RoutePathName } from "../routes";
import { useLayout } from "../context/LayoutContext";
import { hasPermission } from "../helpers/security";
import { Permission, PermissionRight } from "../queries/api/types";
import { useAuth } from "../context/AuthContext";

const getSelectedKey = (location: Location) => {
    return location.pathname;
};

interface MenuItem {
    key: string;
    to?: LinkProps["to"];
    href?: string; // used for outgoing links
    target?: string; // used for outgoing links
    onClick?: () => void;
    label: ReactNode;
    icon?: any;
    children?: MenuItem[];
    childKeys?: string[];
    disabled?: boolean;
    permissions?: Array<{ permission: Permission; permissionRight?: PermissionRight }>;
}

const menuItems: MenuItem[] = [
    {
        key: getRoute(RoutePathName.home),
        to: getRoute(RoutePathName.home),
        label: "Home",
        icon: <HomeOutlined />,
    },
    {
        key: getRoute(RoutePathName.usersList),
        to: getRoute(RoutePathName.usersList),
        label: "Users",
        icon: <UserOutlined />,
        permissions: [{ permission: Permission.users }],
    },
    {
        key: getRoute(RoutePathName.customerList),
        to: getRoute(RoutePathName.customerList),
        label: "Clients",
        icon: <ApartmentOutlined />,
        permissions: [{ permission: Permission.organizations }],
    },
    {
        key: "superAdmin",
        label: "Super Admin",
        icon: <StarOutlined />,
        permissions: [{ permission: Permission.superAdmin }],
        children: [
            {
                key: getRoute(RoutePathName.applicationList),
                to: getRoute(RoutePathName.applicationList),
                label: "Applications",
                icon: <AppstoreOutlined />,
                permissions: [{ permission: Permission.superAdmin }],
            },
            {
                key: getRoute(RoutePathName.organizationList),
                to: getRoute(RoutePathName.organizationList),
                label: "Organisations",
                icon: <ApartmentOutlined />,
                permissions: [{ permission: Permission.superAdmin }],
            },
            {
                key: getRoute(RoutePathName.valueListList),
                to: getRoute(RoutePathName.valueListList),
                label: "Listes de valeurs",
                icon: <ProfileOutlined />,
                permissions: [{ permission: Permission.superAdmin }],
            },
            {
                key: getRoute(RoutePathName.roleList),
                to: getRoute(RoutePathName.roleList),
                label: "Rôles",
                icon: <SkinOutlined />,
                permissions: [{ permission: Permission.superAdmin }],
            },
            // Commented until API MR https://gitlab.onefirst.dev/ezeedev/api-boilerplate/-/merge_requests/9 is merged
            // {
            //     key: getRoute(RoutePathName.jobList),
            //     to: getRoute(RoutePathName.jobList),
            //     label: "Jobs",
            //     icon: <ToolOutlined />,
            //     permissions: [{permission: Permission.superAdmin}],
            // },
        ],
    },
];

const getDefaultOpenKeys = (pathname: string) => {
    const topLevelItem = menuItems.find((menuItem) =>
        menuItem.children?.some(
            (child) =>
                child.key === pathname ||
                child.childKeys?.includes(pathname) ||
                pathname.startsWith(child.key) ||
                child.childKeys?.some((childKey) => pathname.startsWith(childKey.substring(0, -1)))
        )
    );

    return topLevelItem ? [topLevelItem.key] : undefined;
};

const MainMenu: FC = () => {
    const { user } = useAuth();
    const { setIsSidebarCollapsed, isSidebarCollapsed } = useLayout();
    const location = useLocation();
    const onCollapse: SiderProps["onCollapse"] = (value) => {
        setIsSidebarCollapsed(value);
    };

    return (
        <Layout.Sider
            collapsible
            collapsed={isSidebarCollapsed}
            onCollapse={onCollapse}
            trigger={null}
            breakpoint="lg"
            theme="light"
            id="sidebar"
        >
            <div id="sidebar-wrapper">
                <div>
                    <Menu
                        mode="inline"
                        selectedKeys={[getSelectedKey(location)]}
                        defaultOpenKeys={getDefaultOpenKeys(location.pathname)}
                    >
                        {menuItems
                            .filter((item) =>
                                item.permissions
                                    ? item.permissions?.some(({ permission, permissionRight }) =>
                                          hasPermission(user, permission, permissionRight ?? PermissionRight.read)
                                      )
                                    : true
                            )
                            .map((item) => {
                                if (!item.children) {
                                    return (
                                        <Menu.Item key={item.key} icon={item.icon} disabled={item.disabled}>
                                            {item.href ? (
                                                <a href={item.href} target={item.target}>
                                                    {item.label}
                                                </a>
                                            ) : (
                                                <Link to={item.to!}>{item.label}</Link>
                                            )}
                                        </Menu.Item>
                                    );
                                } else {
                                    return (
                                        <Menu.SubMenu
                                            key={item.key}
                                            disabled={item.disabled}
                                            title={item.label}
                                            icon={item.icon}
                                        >
                                            {item.children
                                                .filter((subItem) =>
                                                    subItem.permissions
                                                        ? subItem.permissions.some(({ permission, permissionRight }) =>
                                                              hasPermission(
                                                                  user,
                                                                  permission,
                                                                  permissionRight ?? PermissionRight.read
                                                              )
                                                          )
                                                        : true
                                                )
                                                .map((subItem) => (
                                                    <Menu.Item
                                                        key={subItem.key}
                                                        icon={subItem.icon}
                                                        disabled={subItem.disabled}
                                                    >
                                                        {subItem.href ? (
                                                            <a href={subItem.href} target={subItem.target}>
                                                                {subItem.icon}
                                                                <span>{subItem.label}</span>
                                                            </a>
                                                        ) : (
                                                            <Link to={subItem.to!}>{subItem.label}</Link>
                                                        )}
                                                    </Menu.Item>
                                                ))}
                                        </Menu.SubMenu>
                                    );
                                }
                            })}
                    </Menu>
                </div>
                <div className="collapse">
                    <Button
                        type="link"
                        id="sidebar-collapse-button"
                        onClick={setIsSidebarCollapsed.bind(null, !isSidebarCollapsed)}
                    >
                        {isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    </Button>
                    <Typography.Text type="secondary">v{constants.VERSION}</Typography.Text>
                </div>
            </div>
        </Layout.Sider>
    );
};

export default MainMenu;
