import { FC, FunctionComponent, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

import "./assets/styles/App.less";

import { Permission } from "./queries/api/types";
import { useLogs } from "./context/LogsContext";
import lazyImport from "./helpers/import";
import { getRawRoute, RoutePathName } from "./routes";
import ProtectedRoute from "./components/ProtectedRoute";
import MainLayout from "./components/MainLayout";
import CookieBanner from "./components/CookieBanner";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./pages/error";
import PermissionRoute, { PermissionRouteProps } from "./components/PermissionRoute";
import Maintenance from "./components/Maintenance";
import PageSpinner from "./components/PageSpinner";

const Login = lazyImport(() => import("./pages/login"));
const ForgottenPassword = lazyImport(() => import("./pages/forgottenPassword"));
const ResetPassword = lazyImport(() => import("./pages/resetPassword"));
const Home = lazyImport(() => import("./pages/home"));
const UsersList = lazyImport(() => import("./pages/usersList"));
const UserDetails = lazyImport(() => import("./pages/userDetails"));
const Privacy = lazyImport(() => import("./pages/privacy"));
const ApplicationsList = lazyImport(() => import("./pages/superAdmin/applications"));
const CustomersList = lazyImport(() => import("./pages/customers"));
const ValueList = lazyImport(() => import("./pages/superAdmin/valueList"));
const RoleList = lazyImport(() => import("./pages/superAdmin/roles"));
const JobList = lazyImport(() => import("./pages/superAdmin/jobs"));
const OrganizationsList = lazyImport(() => import("./pages/superAdmin/organizations"));

const routes: Array<PermissionRouteProps & { Children: FunctionComponent }> = [
    {
        path: getRawRoute(RoutePathName.home),
        Children: Home,
        exact: true,
    },
    {
        path: getRawRoute(RoutePathName.usersDetails),
        permissions: [{ permission: Permission.users }],
        Children: UserDetails,
    },
    {
        path: getRawRoute(RoutePathName.usersList),
        permissions: [{ permission: Permission.users }],
        Children: UsersList,
    },
    {
        path: getRawRoute(RoutePathName.customerList),
        permissions: [{ permission: Permission.organizations }],
        Children: CustomersList,
    },
    {
        path: getRawRoute(RoutePathName.privacy),
        Children: Privacy,
    },
    {
        path: getRawRoute(RoutePathName.applicationList),
        permissions: [{ permission: Permission.superAdmin }],
        Children: ApplicationsList,
    },
    {
        path: getRawRoute(RoutePathName.organizationList),
        permissions: [{ permission: Permission.superAdmin }],
        Children: OrganizationsList,
    },
    {
        path: getRawRoute(RoutePathName.valueListList),
        permissions: [{ permission: Permission.superAdmin }],
        Children: ValueList,
    },
    {
        path: getRawRoute(RoutePathName.roleList),
        permissions: [{ permission: Permission.superAdmin }],
        Children: RoleList,
    },
    {
        path: getRawRoute(RoutePathName.jobList),
        permissions: [{ permission: Permission.superAdmin }],
        Children: JobList,
    },
];

const App: FC = () => {
    const { sendLogs } = useLogs();

    return (
        <ErrorBoundary sendLogs={sendLogs}>
            <Maintenance />
            <Suspense fallback={<PageSpinner isFixed />}>
                <Switch>
                    <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
                    <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
                    <Route exact path={getRawRoute(RoutePathName.resetPassword)} component={ResetPassword} />
                    <ProtectedRoute>
                        <CookieBanner />
                        <MainLayout>
                            <Suspense fallback={<PageSpinner />}>
                                <ErrorBoundary sendLogs={sendLogs}>
                                    <Switch>
                                        {routes.map(({ Children, ...props }) => (
                                            <PermissionRoute
                                                key={
                                                    Array.isArray(props.path)
                                                        ? props.path.join("")
                                                        : (props.path as string | undefined)
                                                }
                                                {...props}
                                            >
                                                <Children />
                                            </PermissionRoute>
                                        ))}

                                        <Route path="*">
                                            <ErrorPage />
                                        </Route>
                                    </Switch>
                                </ErrorBoundary>
                            </Suspense>
                        </MainLayout>
                    </ProtectedRoute>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export default App;
