import { Component } from "react";
import { Typography, Card } from "antd";

import constants from "../config/constants";
import { AuthContext, AuthContextType } from "../context/AuthContext";
import { LogsContextTypes } from "../context/LogsContext";

interface ErrorBoundaryState {
    error: any;
    errorInfo: any;
}

interface ErrorBoundaryProps {
    sendLogs: LogsContextTypes["sendLogs"];
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public static contextType = AuthContext;

    public state: ErrorBoundaryState = {
        error: null,
        errorInfo: null,
    };

    public componentDidCatch(error: any, errorInfo: any) {
        const now = `${+new Date()}000000`;
        const stack = (errorInfo.componentStack || "").split("\n").map((trace: string) => [now, trace]);

        if (constants.ENABLE_DEBUG_LOGS) {
            this.props.sendLogs.mutate({
                streams: [
                    {
                        stream: {
                            app: constants.DEBUG_LOGS_APP,
                            organization: (this.context as AuthContextType).user?.organization?.id ?? null,
                        },
                        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                        values: [[now, `${error}`], ...stack],
                    },
                ],
            });
        }

        this.setState({ error, errorInfo });
    }

    public render() {
        if (this.state.error) {
            return (
                <div className="container">
                    <Typography.Title level={1}>
                        Nous sommes désolés, une erreur est survenue, veuillez réessayer plus tard.
                    </Typography.Title>
                    <Card>
                        <details className="whitespace-pre-wrap">
                            {this.state.error?.toString()}
                            <br />
                            {this.state.errorInfo?.componentStack}
                        </details>
                    </Card>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
