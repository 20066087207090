import { useQuery, UseQueryOptions } from "@tanstack/react-query";

import { infos, ResponseError } from "./api";
import { ApiInfos } from "./api/types";

export const infoKeys = {
    all: ["infos"],
};

export const useApiInfos = <TData = ApiInfos>(options?: UseQueryOptions<ApiInfos, ResponseError, TData>) => {
    return useQuery<ApiInfos, ResponseError, TData>(options?.queryKey ?? infoKeys.all, infos, options);
};
