import { AxiosError } from "axios";

import * as auth from "./auth";
import api from "./apiClient";
import { ApiInfos } from "./types";

export interface APIError {
    name: string;
    status: number;
    message: string;
    fields?: { [field: string]: string };
    index?: { [field: string]: string | number | boolean };
    conflict?: { [field: string]: unknown };
}

export type ResponseError = AxiosError<APIError>;

export interface PaginationQuery {
    page?: number;
    pageSize?: number;
    sort?: string;
    sortOrder?: string;
}

export interface SearchQuery {
    search?: string;
}

export type SearchPaginationQuery = PaginationQuery & SearchQuery;

export default {
    auth,
};

export const infos = async () => await api.get<ApiInfos>("/").then((response) => response?.data);
