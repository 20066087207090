import { Spin, Typography } from "antd";
import { VFC } from "react";

import constants from "../config/constants";
import logo from "../assets/images/logo.svg";
import Seo from "./Seo";
import { useApiInfos } from "../queries";

/*
    Maintenance message to avoid displaying the app if the API returns an error.
    Use cases :
    - Unscheduled API downtime (eg 504)
    - Scheduled API downtime (eg 502 during long API deployments, data migration...)
*/
const Maintenance: VFC = () => {
    const { isInitialLoading, isRefetching, isError } = useApiInfos({
        enabled: constants.ENABLE_MAINTENANCE_CHECK,
        staleTime: 60 * 1000, // consider stale every minute
        retry: 2,
    });

    // if setting is not enabled, bail and don't display maintenance message
    if (!constants.ENABLE_MAINTENANCE_CHECK) {
        return null;
    }

    // show spinner while initial API request is in progress (does not occur when refetching after stale data)
    if (isInitialLoading && !isRefetching) {
        return (
            <div className="fixed inset-0 z-50 bg-white flex items-center">
                <div id="initial-loader">
                    <Spin size="large" />
                </div>
            </div>
        );
    }

    // show maintenance page when API returns an error status (4xx or 5xx)
    if (isError) {
        document.body.style.overflow = "hidden";
        return (
            <div className="fixed inset-0 z-50 bg-white flex flex-col items-center pt-14 text-center">
                <Seo title="Maintenance en cours" />
                <img className="logo mb-8" src={logo} alt="logo" />
                <Typography.Title>Le site est actuellement en maintenance.</Typography.Title>
                <Typography.Paragraph>
                    Veuillez réessayer plus tard. <br />
                    Nous vous remercions de votre compréhension.
                </Typography.Paragraph>
                <Typography.Paragraph>À bientôt</Typography.Paragraph>
            </div>
        );
    }

    if (document.body.style.overflow === "hidden") {
        document.body.style.overflow = "auto";
    }

    // all is good, don't show maintenance message
    return null;
};

export default Maintenance;
