import { Button, Drawer, Form, FormProps } from "antd";
import { antdDrawer, useModal, create } from "@ebay/nice-modal-react";

import UserAccountFields from "../forms/UserAccountFields";
import { useAuth } from "../../context/AuthContext";
import { defaultErrorMessage } from "../../i18n";
import { errorMessage, successMessage } from "../../helpers/message";

const ProfileFormDrawer = create((props) => {
    const modal = useModal();
    const [form] = Form.useForm();
    const { user, updateUser } = useAuth();
    const { mutate: update, isLoading } = updateUser;
    const onFormValidSubmit: FormProps["onFinish"] = (values) => {
        update(
            { ...values.user },
            {
                onSuccess: () => {
                    modal.hide();
                    successMessage({
                        content: "Votre profil a été modifié avec succès",
                    });
                },
                onError: (error) => {
                    errorMessage({
                        content: `${defaultErrorMessage} (${error?.response?.status ?? 0})`,
                    });
                },
            }
        );
    };

    return (
        <Drawer width={500} title="Votre profil" {...props} {...antdDrawer(modal)}>
            <Form
                form={form}
                onFinish={onFormValidSubmit}
                layout="vertical"
                initialValues={{
                    user,
                }}
                scrollToFirstError
            >
                <UserAccountFields isMe />
                <Form.Item className="mb-32 mt-32" shouldUpdate>
                    {() => (
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={isLoading}
                            disabled={
                                !form.isFieldsTouched() ||
                                form.getFieldsError().filter(({ errors }) => errors.length).length > 0
                            }
                            block
                        >
                            Enregistrer les modifications
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </Drawer>
    );
});

export default ProfileFormDrawer;
